
.feedback-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.feedback-form {
  max-width: 500px;
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.submit-button {
  margin-top: 20px;
}

.input_customer
{
  border-bottom: 1px solid  white !important;
  color: white !important;
}

.input_customer_label
{

  color: white;

}
label
{
  color: white !important;
}
input
{
  color: white !important;
}